@import "../../tools";

.text-input {
  position: relative;

  $input: null !default;
  $label: null !default;

  &__input {
    $input: &;
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: 1rem;
    border-radius: 2px;
    padding: 12px 10px;
    margin: 0 0 1.07143rem;
    color: inherit;
    @include background($gray--1, $white);
    border: 1px solid;
    @include border-color($gray-1, $gray-2);

    &:focus {
      @include border-color($gray-2, $gray-1);
    }
  }

  &__label {
    $label: &;
    @include background($gray--1, $white);
    position: absolute;
    top: 12px;
    left: 11px;
    font-size: 1rem;
    padding: 0;
    transition: all 250ms;
    @include color($gray-1, $gray-1);
    pointer-events: none;
  }

  #{$input}:focus + #{$label},
  #{$input}:not(#{$input}--empty) + #{$label} {
    font-size: 0.8125rem;
    top: -8px;
    left: 7px;
    padding: 0 5px;
    @include color($gray-2, $text-color);
  }
}
