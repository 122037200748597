@import "../../tools";

.info-banner-container {
  overflow: hidden;
}

.info-banner {
  display: flex;
  position: relative;
  gap: 10px;
  background: color("orange-500");
  padding: 13px 20px;
  color: color("gray-700");

  &__icon {
    width: 20px;
    align-self: start;
    margin-top: 2px;
  }

  &__heading {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 1px;
  }

  &__body {
    font-size: 0.875rem;
    margin: 0;
  }

  &__dismiss {
    position: absolute;
    padding: 18px 20px 20px 20px;
    right: 0;
    top: 0;
    cursor: pointer;

    &__icon {
      display: block;
      width: 15px;
    }
  }
}
