.toast {
  &__close-button {
    display: block;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 1px;
    flex-shrink: 0;
  }
}

:global {
  .Toastify {
    &__toast-container {
      width: 400px;
    }

    &__toast {
      min-height: 52px;
      font-family: inherit;
      font-weight: 500;
      border-radius: 3px;
      padding: 14px 20px;
    }
  }
}
