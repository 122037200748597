@import "../../tools";

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba($gray--1, 0.7);
  z-index: 99999;
  display: flex;
  max-height: 100vh;
  overflow: auto;
  padding: 30px 0;

  &__full {
    align-items: stretch;
  }

  &__box {
    position: relative;
    margin: auto;
    max-width: 480px;
    width: 100%;
    @include background($gray--2, $white);
    padding: 40px;
    border-radius: 3px;
    animation: slideUpFade 400ms;

    &__full {
      max-width: 100%;
      // align-self: self-end;
    }

    &__close-button {
      position: absolute;
      top: 15px;
      right: 14px;
      width: 12px;
      @include color(rgba($white, 0.6), rgba($black, 0.6));
      cursor: pointer;
      transition: color 250ms;

      &:hover {
        @include color($white, $black);
      }
    }

    > header {
      font-family: "Overpass", sans-serif;
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 25px;
    }

    > footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 25px;

      > * {
        margin-left: 10px;
      }
    }
  }

  @keyframes slideUpFade {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
