@import "../../tools";

.iframe-synchronize-search {
  cursor: pointer;
  margin-right: 15px;
  transition: color 250ms;

  &--enabled {
    color: $green;
  }

  &__icon {
    display: block;
    width: 25px;
    height: auto;
  }
}
