@import "../tools";

.footer {
  @include background($gray--2, $gray-4);
  padding: 50px;
  z-index: 1;

  &__menu {
    display: flex;
    flex-wrap: wrap;

    &__col {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      &:not(:last-child) {
        border-right: 8px solid;
        @include border-color($gray--1, $white);
      }

      @media only screen and (max-width: 1150px) {
        width: 50%;
        justify-content: initial;

        &:nth-child(even) {
          border-right: none;
        }
      }

      @media only screen and (max-width: 700px) {
        width: 100%;
        &:not(:last-child) {
          border-right: none;
        }
      }

      &__container {
        padding: 0 20px;

        @media only screen and (max-width: 1150px) {
          padding: 10px 20px;
        }

        &__logo {
          width: 100%;
          max-width: 190px;
          margin-bottom: 20px;
        }

        &__header {
          font-family: "Overpass", sans-serif;
          font-size: 1.125rem;
          text-transform: uppercase;
          margin-bottom: 8px;
        }

        &__item {
          display: block;
          font-size: 1.375rem;
          font-weight: 300;
          padding-bottom: 3px;
          margin-bottom: 5px;

          a {
            color: inherit;
            text-decoration: none;
            border-bottom: 1px solid;
            @include border-color($gray-2, $text-color);

            &:hover {
              border-color: $orange;
            }
          }

          &--social {
            a {
              display: flex;
              align-items: center;
              border-bottom: none;
            }

            svg {
              width: 23px;
              margin-right: 30px;
            }
          }
        }
      }
    }
  }

  &__bottom {
    text-align: center;
    margin-top: 30px;
    font-size: 1.25rem;
    font-weight: 300;
  }
}
