@import "../tools";

.user-menu {
  position: relative;

  &__trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      @include color($white, $black);
    }

    &__user-icon {
      width: 25px;
      margin-right: 10px;

      @media screen and (max-width: 900px) {
        margin-right: 0;
      }
    }

    &__login-icon {
      width: 38px;
      margin-right: 10px;

      @media screen and (max-width: 900px) {
        margin-right: 0;
      }
    }

    &__name {
      white-space: nowrap;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  &__dropdown {
    position: absolute;
    @include background($gray--3, $white);
    margin-top: 10px;
    padding: 5px 0;
    right: 0;
    min-width: 280px;
    box-shadow: 0 0 8px 0 rgba(32, 36, 53, 0.2);

    &__item {
      display: block;
      @include color($gray-4, $gray--2);
      padding: 10px 30px;
      text-decoration: none;
      cursor: pointer;
      transition: background 250ms;

      img {
        width: 22%;
        float: left;
        margin-right: 10px;
      }

      &:hover {
        @include background($gray-1, $gray-4);
      }
    }

    &__divider {
      height: 1px;
      @include background($gray--2, $gray-3);
      margin: 5px 0;
    }

    &__text {
      padding: 15px 30px 15px 20px;
      color: $gray-1;
      font-size: 0.75rem;
    }
  }
}
