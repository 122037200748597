@import "../tools";

.header {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  height: 78px;
  @include background($gray--2, $white);
  border-bottom: 1px solid;
  @include border-color($gray--1, $gray-3);
  position: relative;
  z-index: 2;

  &__logo {
    margin-right: auto;
    color: inherit;

    @media screen and (max-width: 680px) {
      display: none;
    }

    > svg {
      display: flex;
      width: 160px;
      margin-top: 4px;
    }
  }

  &__search-field {
    margin: 0 15px;

    @media screen and (max-width: 680px) {
      margin-left: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__user-menu {
      margin-left: 45px;

      @media screen and (max-width: 900px) {
        margin-left: 0;
      }
    }
  }

  &__hamburger-menu {
    margin-right: 15px;
    cursor: pointer;
    span {
      @include background($gray-3 !important, $gray--2 !important);
    }
  }
}
