@import "../../tools";

.button {
  position: relative;
  background: transparent;
  @include color($gray-2, $text-color);
  border: 1px solid;
  @include border-color($gray-1, $gray-2);
  border-radius: 3px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  padding: 8px 18px;
  cursor: pointer;
  transition: opacity 200ms;

  &--primary {
    background: $green;
    border-color: $green !important;
    color: $white !important;
  }

  &--primary-inverted {
    background: transparent;
    border-color: transparent !important;
    color: $green !important;
    padding-left: 9px;
    padding-right: 9px;
  }

  &--block {
    width: 100%;
  }

  &--secondary {
    border: none;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  $content: null !default;

  &__content {
    $content: &;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    opacity: 1;
    transition: all 200ms;

    > svg {
      width: 100%;
      max-width: 16px;
      max-height: 16px;
      margin-right: 7px;
    }
  }

  $loading-indicator: null !default;
  &__loading-indicator {
    $loading-indicator: &;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding: 4px;
    background: inherit;
    opacity: 0;
    transition: opacity 200ms;

    &__svg {
      height: 100%;
      color: inherit;
      transform: rotate(-88deg);

      &__circle {
        transition: stroke-dashoffset 300ms linear;
        stroke-dasharray: 113;
      }
    }
  }

  &--loading {
    #{$content} {
      transform: scale(0.95);
      opacity: 0;
    }

    #{$loading-indicator} {
      opacity: 1;
    }
  }
}
