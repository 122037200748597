@mixin color($dark-color, $light-color) {
  @include genericThemeCode("color", $dark-color, $light-color);
}

@mixin background($dark-color, $light-color) {
  @include genericThemeCode("background", $dark-color, $light-color);
}

@mixin background-image($dark-color, $light-color) {
  @include genericThemeCode("background-image", $dark-color, $light-color);
}

@mixin background-color($dark-color, $light-color) {
  @include genericThemeCode("background-color", $dark-color, $light-color);
}

@mixin border-color($dark-color, $light-color, $important: false) {
  @include genericThemeCode("border-color", $dark-color, $light-color, $important);
}

@mixin box-shadow($dark-color, $light-color) {
  @include genericThemeCode("box-shadow", $dark-color, $light-color);
}

@mixin genericThemeCode($key, $dark-color, $light-color, $important: false) {
  $important-flag: "";
  @if ($important) {
    $important-flag: "!important";
  }

  body:global(.dark-theme) & {
    #{$key}: $dark-color #{$important-flag};
  }

  body:global(.light-theme) & {
    #{$key}: $light-color #{$important-flag};
  }

  body:global(.default-theme) & {
    #{$key}: $light-color #{$important-flag};

    @media (prefers-color-scheme: dark) {
      #{$key}: $dark-color #{$important-flag};
    }
  }
}
