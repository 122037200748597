@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap");
@import "../global/config";
@import "../plugins/react-tippy";
@import "../plugins/embedded-service-help-button";

* {
  box-sizing: border-box;

  &::before,
  &::after {
    @extend *;
  }

  &:focus {
    outline: none;
  }
}

html {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  min-height: 100%;

  &.dark-theme {
    background: $gray--1;
    color: $gray-2;
  }

  &.light-theme {
    background: $white;
  }

  &.default-theme {
    background: $white;
    @media (prefers-color-scheme: dark) {
      background: $gray--1;
      color: $gray-2;
    }
  }

  &.disable-scroll {
    margin-right: 16px;
    overflow-y: hidden;
  }
}

strong {
  font-weight: 500;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
