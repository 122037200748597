@import "../tools";

.search {
  display: flex;
  width: 100%;
  max-width: 675px;
  position: relative;

  &__icon {
    position: absolute;
    width: 15px;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;

    &__right {
      right: 12px;
      left: unset;
    }

    &__warning {
      right: 40px;
    }
  }

  &__input-container {
    display: flex;
    width: 100%;
    position: relative;
    margin: 0 2px 0 0;

    &__suggestions {
      position: absolute;
      top: 100%;
      width: 100%;
      @include background($gray--3, #fff);
      box-shadow: 0 3px 6px #00000033;
      padding: 12px 15px;
      margin-top: 7px;

      &__item {
        font-size: 0.875rem;
        font-weight: 500;
        margin: 8px 0;
        cursor: pointer;

        &--selected {
          color: $green;
        }

        &:hover {
          color: $green;
        }
      }
    }
  }

  &__input {
    width: 100%;
    flex-grow: 1;
    font-family: inherit;
    font-size: 1rem;
    color: inherit;
    @include background($gray--1, $gray-4);
    border: 1px solid transparent;
    border-radius: 0;
    padding: 8px 35px 8px 14px;

    &__right {
      padding: 8px 35px 8px 8px;
    }

    &:focus {
      outline: none;
      border-color: $green;
    }

    &__warning {
      &:focus {
        border-color: #ffae00;
      }
    }
  }

  &__clear-input {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 1.3rem;
    height: 1.3rem;
    display: block;
    transform: translateY(-50%);
    background-size: contain;
    z-index: 20;
    cursor: pointer;

    @media screen and (max-width: 680px) {
      right: 125px;
    }
  }

  &__select {
    $select: &;
    flex-shrink: 0;
    flex-basis: 180px;

    @media screen and (max-width: 680px) {
      flex-basis: 115px;
    }

    :global {
      #{$select} {
        &__control {
          border-radius: 0;
          @include background($gray--1, $gray-4);
          border: 1px solid transparent;
          flex-wrap: nowrap;

          &:hover {
            border-color: transparent;
          }

          &--is-focused {
            box-shadow: none;
            border-color: $green;

            &:hover {
              border-color: $green;
            }
          }
        }

        &__menu {
          @include background($gray--3, $white);
          border-radius: 0;
        }

        &__menu-list {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        &__option {
          &--is-focused {
            @include background($gray-1, $gray-4);
          }

          &--is-selected {
            @include background($green, $green);
          }
        }

        &__value-container {
          padding: 6px 8px;
        }

        &__single-value {
          color: inherit;
        }

        &__indicator-separator {
          background: $gray-2;
          margin-bottom: 8px;
          margin-top: 8px;
        }
      }
    }
  }
}
