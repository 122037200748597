@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/animations/shift-away-subtle.css";

.tippy-box {
  text-align: left !important;
  border-radius: 0 !important;
  padding: 4px 8px !important;

  .tippy-content {
    line-height: 1.6;
    font-weight: 500;

    a {
      opacity: 1;
      color: #fff !important;
      display: inline;
      text-decoration: underline;
    }
  }
}
