$text-color: #323232;
$error-text-color: #ff0000;
$white: #ffffff;
$black: #000000;
$green: #30826a;
$orange: #ff9900;

$gray--1: #121212;
$gray--2: #272727;
$gray--3: #414141;
$gray-1: #707070;
$gray-2: #cbcbcb;
$gray-3: #eeeeee;
$gray-4: #eff1f4;
